<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">

          <TableReportResearchsApprove
              :columnsList="columns"
              :tableData="dataValues"
              :onClickedRows="onClickRows"
              :ReportPDF="ReportPDF"
              :ReportWord="ReportWord"
              :paginationConfig="paginationConfig"
              :filterTable="filterTable"
              :sortBy="sort_by"
              :sortChanged="
                (v) => {
                  sort_by = v;
                  fetchReportResearchsList();
                }
              "
            />
      </div>
    </div>
  </template>
  <script>
  import TableReportResearchsApprove from "../../components/TableReportResearchsApprove.vue";

  
  import { HTTP } from "../../http-common";
  import DateFunction from "../../services/DateFunction";
  
  export default {
    name: "ReportResearchsApprove",
    components: {
      TableReportResearchsApprove,


    },
    mounted() {
      this.fetchReportResearchsList();
      this.$store.commit("setBread", this.$router.currentRoute._value);
    },
    methods: {
      async fetchReportResearchsList() {

        let loading = this.$loading.show();

      let body = {
        limit: this.paginationConfig.pageSize,
        offset:
          this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
        search_value: {
          // ...this.search_value,
          // letter_project_begin: DateFunction.getSearchDate(
          //   this.search_value.letter_project_begin
          // ),
          // letter_project_end: DateFunction.getSearchDate(
          //   this.search_value.letter_project_end
          // ),
        },
        
      };


        // let loading = this.$loading.show();

        // let body = {
        //   limit: this.paginationConfig.pageSize,
        //   offset:
        //     this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
          
        // };

      
        const { data } = await HTTP.post("/getReportResearchsApprove", body);

        this.research_name = data.research_name;
        this.letter_project_topic = data.letter_project_topic;
     
        // this.approves_by = data.approves_by;
        
        // this.letter_project_topic = data.letter_project_topic;
        // this.letter_project_begin = data.letter_project_begin;
        // this.letter_project_end = data.letter_project_end;
        // this.attach_1 = data.attach_1;
        // this.attach_2 = data.attach_2;
        // this.attach_3 = data.attach_3;

        if (data.data && data.data.length > 0) {
          this.dataValues = data.data;
          this.paginationConfig.total = data.total;
          
          loading.hide();
        } else {
          loading.hide();
          this.dataValues = [];
          this.paginationConfig.total = 0;
        }
        loading.hide();
      },
      onClickRows(data) {
        this.$router.push(`/research-details-approve/${data.id}`);
      },

      handlePageChange(page) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: page,
        };
        this.fetchResearchList();
      },

      handlePageSizeChange(current, pageSize) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: current,
          pageSize: pageSize,
        };
      },

    },

    data() {
      return {
        loader: "",
        search_value: null,
        sortOptions: 0,

        columns: [
          {
            title: "ชื่องานวิจัย",
            dataIndex: "letter_project_topic",
            width: 250,
          },
          {
            title: "ชื่อผู้เบิก/หัวหน้าโครงการ",
            dataIndex: "letter_project_head",
            width: 150,
          },
          {
            title: "งวดที่",
            dataIndex: "letter_money_round",
            width: 80,
          },
          {
            title: "จำนวนเงินที่ต้องการเบิก (ครั้งนี้)",
            dataIndex: "letter_money",
            width: 150,
          },
          {
            title: "งบประมาณ",
            dataIndex: "letter_project_budget",
            width: 150,
          },
          {
            title: "เลขที่หนังสือ/ลงวันที่",
            dataIndex: "letter_code",
            width: 150,
          },
          {
            title: "วันที่เริ่มต้นโครงการ",
            dataIndex: "letter_project_begin",
            width: 150,
          },
          {
            title: "วันที่สิ้นสุดโครงการ",
            dataIndex: "letter_project_end",
            width: 150,
          },
          {
            title: "พิจารณา/อนุมัติ",
            dataIndex: "approve_status",
            align: "center",
            width: 80,
          },
          {
            title: "รายงาน",
            dataIndex: "report_data",
            align: "center",
            width: 80,
          },
        ],
     
        paginationConfig: {
          total: 0,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          onChange: this.handlePageChange,
          onShowSizeChange: this.handlePageSizeChange,
        },

        dataValues: [],
        letter_project_topic: [],
        letter_project_begin: [],
        letter_project_end: [],
        research_name: [],
        dataLength: 0,
        
      };
    },
    

    
  };
  </script>
  
  <style scoped></style>