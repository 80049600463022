<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">

          <TableReportPublished
              :columnsList="columns"
              :tableData="dataValues"
              :onClickedRows="onClickRows"
              :ReportPDF="ReportPDF"
              :ReportWord="ReportWord"
              :paginationConfig="paginationConfig"
              :filterTable="filterTable"
              :sortBy="sort_by"
              :sortChanged="
                (v) => {
                  sort_by = v;
                  fetchReportPublishedList();
                }
              "
            />
      </div>
    </div>
  </template>
  <script>
  import TableReportPublished from "../../components/TableReportPublishedApprove.vue";

  
  import { HTTP } from "../../http-common";
  import DateFunction from "../../services/DateFunction";
  
  export default {
    name: "ReportPublishedList",
    components: {
      TableReportPublished,

    },
    mounted() {
      this.fetchReportPublishedList();
      this.$store.commit("setBread", this.$router.currentRoute._value);
    },
    methods: {
      async fetchReportPublishedList() {
        let loading = this.$loading.show();

        let body = {
          limit: this.paginationConfig.pageSize,
          offset:
            this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
            search_value: {
          ...this.search_value,
        },
          
        };

        const { data } = await HTTP.post("/getReportPublishedApprove", body);

        this.research_name = data.research_name;
        this.attach_1 = data.attach_1;
        this.attach_2 = data.attach_2;
        this.attach_3 = data.attach_3;

      
        if (data.data && data.data.length > 0) {
          this.dataValues = data.data;
          this.paginationConfig.total = data.total;


          loading.hide();
        } else {
          loading.hide();
          this.dataValues = [];
          this.paginationConfig.total = 0;
        }
        loading.hide();
      },
      onClickRows(data) {
        this.$router.push(`/published-details-approve/${data.id}`);
      },

      
      handlePageChange(page) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: page,
        };
        this.fetchReportPublishedList();
      },

      handlePageSizeChange(current, pageSize) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: current,
          pageSize: pageSize,
        };
      },
    },

    data() {
      return {
        loader: "",
        search_value: null,
        sortOptions: 0,

        columns: [
          {
            title: "เลขที่หนังสือ/ลงวันที่",
            dataIndex: "letter_code",
            width: 150,
          },
          {
            title: "ผู้ขอรับค่าตีพิมพ์/ผู้จัดทำ",
            dataIndex: "paper_owner_id",
            width: 150,
          },
          {
            title: "ชื่อบทความ",
            dataIndex: "paper_name",
            width: 150,
          },
          {
            title: "ชื่อวารสาร",
            dataIndex: "paper_journal",
            width: 150,
          },
          {
            title: "พิจารณา/อนุมัติ",
            dataIndex: "approve_status",
            align: "center",
            width: 80,
          },
          {
            title: "รายงาน",
            dataIndex: "report_data",
            align: "center",
            width: 80,
          },
        ],
     
        paginationConfig: {
          total: 0,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          onChange: this.handlePageChange,
          onShowSizeChange: this.handlePageSizeChange,
        },

        dataValues: [],
        paper_owner_id: [],
        dataLength: 0,
        research_name: [],
      };
    },
    

    
  };
  </script>
  
  <style scoped></style>