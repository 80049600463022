<template>
  <div class="flex flex-col gap-4">

    <div
      class="p-4 rounded-main boxShadow-main flex justify-between items-center"
      :style="{ backgroundColor: '#FFF' }"
      v-if="isHeader"
    >

    
      
      <p class="text-xs">
        {{
          `หน้าที่ ${paginationConfig.current} / ${
            paginationConfig.current * paginationConfig.pageSize -
            paginationConfig.pageSize +
            1
          } - ${paginationConfig.current * paginationConfig.pageSize} จาก ${
            paginationConfig.total
          } รายการ`
        }}
      </p>
     
    </div>

    <div
      class="rounded-main boxShadow-main"
      :style="{ backgroundColor: '#FFF', overflow: 'hidden' }"
    >
    <!-- :customRow="customRow" -->
      <a-table
        :columns="columnsList"
        :data-source="numberedData"
        :pagination="paginationConfig"
        :locale="{ emptyText: 'ไม่พบข้อมูล' }"
        class="custom-table"
        :row-class-name="(_record, index) => getRowClassName(_record)"
      >
        <template #bodyCell="{ column, record }">
          
   
            <template v-if="column.dataIndex === 'power_topic'">
              
              {{
                column.dataIndex === "power_topic"? (record.power_topic): ""
              }}
              <hr>
  
              <div v-if="record.attach_1 != null">
              <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${record.attach_1}`"  target="_blank" style="display: ruby;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                  <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                </svg>
                เอกสารแนบ 1
              </a>
              </div>
              <div v-if="record.attach_2 != null">
                <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${record.attach_2}`"  target="_blank" style="display: ruby;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                    <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                  </svg>
                  เอกสารแนบ 2
                </a>
              </div>
              <div v-if="record.attach_3 != null">
                <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${record.attach_3}`"  target="_blank" style="display: ruby;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                    <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                  </svg>
                  เอกสารแนบ 3
                </a>
              </div>
              
             
            </template>


          <template
            v-if="
              column.dataIndex === 'power_for' 
            "
            >
            <div>
              {{
                column.dataIndex === "power_for"? (record.power_for)
                : ""
              }}
            </div>
          </template>
          <template
            v-if="
              column.dataIndex === 'letter_code' 
            "
            >
            <div>
              {{
                column.dataIndex === "letter_code"? (record.letter_code) 
                : ""
              }}
            </div>
          </template>
         
  
          <template v-if="column.dataIndex === 'approve_status'">
        
        <div class="flexBox" @click.stop="() => onClickedRows(record)">
          <div v-if="record.is_reject == 1" class="flexBox">
            <span class="status-reject badge">
              <BaseIconVue name="rejectIcon" />
              ตีกลับ-ให้แก้ไข
            </span>
          </div>
          <div v-else-if="record.is_reject == 0" class="flexBox">
            <div v-if="record.is_approved == 1" class="flexBox">
              <span class="status-approve badge">
                <BaseIconVue name="approveBadgeIcon" />
                อนุมัติ
              </span>
            </div>
            <div v-else>
              <!-- {{ record.status_step }} <br>
              {{ record.foundSteps }}  -->
              <!--{{ record.latestStepIndex }} // last step ? 
              {{ record.status_step }} // status approvers ? 
               {{ record.approves_by }} // email approvers --> 
              <span v-if="record.status_step == 1  && record.approves_by.includes($store.state.user.email)" class="status-approve badge">
                <BaseIconVue name="approveBadgeIcon" />
                อนุมัติ
              </span>
              <span v-else class="status-pending badge">
                <BaseIconVue name="pendingIcon" />
                รอการอนุมัติ
              </span>
            </div>
        </div>
        
        </div>
        <!-- <div v-else-if="record.approve_by == $store.state.user.email" class="flexBox">
          <span class="status-reject badge">
            <BaseIconVue name="rejectIcon" />
            ไม่อนุมัติ
          </span>
        </div> -->
        
      </template>

      <template v-if="column.dataIndex === 'report_data'">
            <!-- @click.stop="() => ReportPDF(record)" -->
              <a :href="`https://erol.econ.cmu.ac.th/erol-services/print.php?report=econ_report_2&id=${record.id}`" target="_blank">
              <button> 
              <BaseIconVue
                name="PDFIcon"
              />
              </button>
            </a>
            
            <!-- @click.stop="() => ReportWord(record)" -->
            <a :href="`https://erol.econ.cmu.ac.th/erol-services/export.php?report=econ_report_2&id=${record.id}`" target="_blank">
            <button>
              <BaseIconVue
                name="WordIcon"
              />
            </button>
          </a>
         
          </template>


          

          
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import BaseIconVue from "./Icon/BaseIcon.vue";
import DateFunction from "../services/DateFunction";
import NumberFunction from "../services/NumberFunction";

export default {
  components: {
    BaseIconVue,
  },

  props: {
    isHeader: {
      Type: Boolean,
      default: true,
    },
    // isFilterType: {
    //   Type: Boolean,
    //   default: true,
    // },
    columnsList: {
      Type: String,
    },
    tableData: {
      Type: String,
    },
    onClickedRows: {
      Type: Function,
    },
    dataLength: {
      Type: Number,
      default: 0,
    },
    paginationConfig: {
      Type: String,
    },
    filterTable: {
      Type: String,
    },
    sortBy: {
      Type: Number,
    },
    sortChanged: {
      Type: Function,
    },
    statusChanged: {
      Type: Function,
    },
    
    fileDownload: {
      Type: Function,
      required: false,
    },
    EditClicked: {
      Type: Function,
      required: false,
    },
    DeleteClicked: {
      Type: Function,
      required: false,
    },
    ReportPDF: {
      Type: Function,
      required: false,
    },
    ReportWord: {
      Type: Function,
      required: false,
    },
    
    tableStrip: {
      Type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.valueSort = this.sortBy;
  },

  computed: {
    numberedData() {
      return this.tableData.map((item, index) => {
        return {
          ...item,
          rowNumber: index + 1,
        };
      });
    },
  },

  methods: {
   
    changeValues(value) {
      this.sortChanged(value);
      this.valueSort = value;
    },

    changeTab(tab) {
      this.statusChanged(tab);
      this.activeValue = tab;
    },

    customRow(record) {
      return {
        onClick: () => {
          if (this.onClickedRows) {
            this.onClickedRows(record);
          }
        },
      };
    },

    formatDate(date) {
      if (date) {
        return DateFunction.formatDate(date);
      } else {
        return "-";
      }
    },
    formatNumber(number) {
      if (date) {
        return NumberFunction.formatNumber(number);
      } else {
        return "-";
      }
    },

    getRowClassName(record, index) {
      if (record.manage_data == "edit" && this.tableStrip) {
        return "table-edit";
      } else if (record.manage_data == "delete" && this.tableStrip) {
        return "table-delete";
      }
    },
  },

  data() {
    return {
      valueSort: 0,
      activeValue: "",
      editableData: reactive({}),
    };
  },
};
</script>

<style scoped>
.button-tap {
  border: 0;
  padding: 8px 16px;
  border-radius: 40px;
  height: 52px;
  color: #f39dbe;
  box-shadow: none !important;
}

.button-tap:hover {
  color: rgb(237 64 129 / 100%);
}

.button-tap:focus {
  outline: 0 !important;
}

.active {
  background-color: #f7d5e2;
  color: #ed4081;
}
</style>

